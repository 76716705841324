<template>
  <ion-page>
    <ion-content fullscreen>    
  
        <ion-list class="SurveyFile">
          <span class="title">{{ $t('labelPictureWarning') }}</span>
          <ion-grid>          
             <ion-row size="12">
                <template v-for="index in  10" :key="index">
                  <template v-if="checkList.indexOf(`${index}`) > -1">
                    <ion-col size="6">
                      <ion-label>  
                        <template v-if="photo[index-1] == undefined">
                          <ion-img src="/assets/images/common/no-image.png" @click="handlePhoto(index-1)"/>
                        </template>
                        <template v-else> 
                          <ion-img
                            v-if="photo[index-1]"
                            :src="photo[index-1]"
                            @ionError="photo[index-1] = '/assets/images/common/no-image.png'"
                            @click="handlePhoto(index-1)"
                            />                            
                        </template>
                        <ion-button slot="start" v-if="photo[index-1] === undefined" class="clear" color="tertiary" size="" expand="full" @click="handlePhoto(index-1)">
                          <span>{{$t(`labelPpicSubject${index}`)}}</span>
                          <ion-icon :icon="cameraOutline"></ion-icon>
                        </ion-button>
                        <ion-button slot="start" v-if="photo[index-1]" class="clear" color="tertiary" size="" expand="full" @click="removePhoto(index-1)">
                          <ion-icon :icon="trash"></ion-icon>
                        </ion-button>                   
                      </ion-label>  
                    </ion-col>
                  </template>         
                </template>                
            </ion-row>

          </ion-grid>
        </ion-list>

      <ion-button color="tertiary" size="" expand="full" @click="handleNext">{{$t('buttonLabelNext')}}</ion-button>
      <ion-loading :is-open="isOpenLoading" message="Loading..." spinner="circles"></ion-loading>
    </ion-content>
  </ion-page>
</template>

<script>
import Cookies from 'js-cookie'
import { 
  IonPage,
  IonContent, 
  IonButton, 
  IonList,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  useIonRouter
} from '@ionic/vue'
import { onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { cameraOutline, trash } from 'ionicons/icons'
import { Camera, CameraResultType } from '@capacitor/camera'
import { DataURIToBlobNew } from '@/utils/commonUtils'
import { addFileData } from '@/api/board/common_v2'

export default {
  name: 'mobile-survey-file',
  components: {
    IonPage,
    IonContent,
    IonButton,
    IonList,
    IonLabel,
    IonGrid,
    IonRow,
    IonCol,
    IonLoading
  },
  setup() {
    const route = useRoute()
    const router = useIonRouter()
    const checkList = ref()
    //선언
    let isOpenLoading = ref(false)
    //첨부파일
    let photo = ref([])

    //대상이 존재
    if(route.query.checklist != '' || route.query.checklist !== undefined ){
      checkList.value = route.query.checklist.split(',')
    }else{
      for(let i = 0 ; i < 10 ; i++) {
        checkList.value.push(i);
      }
    }

    onMounted(async () => { 
      
    })

    watch(
      () => route.params,
      async () => {
        if(route.path.indexOf('/mobile/surveyFile') > -1 ){
          photo.value = []
          isOpenLoading.value = false
          if(route.query.checklist != '' || route.query.checklist !== undefined ){
            checkList.value = route.query.checklist.split(',')
          }else{
            for(let i = 0 ; i < 10 ; i++) {
              checkList.value.push(i);
            }
          }          
        }            
      },
      {
        immediate: true
      }
    )

    const dataUpdate = async() => {

      const apiUrl = 'api/atm/hardwareinspection/fileonly/update'

      //파일 정보 처리
      let formData = new FormData()
      photo.value.forEach((e, k) => {
          if(e !== undefined){
            const index = k + 1            
            const file = DataURIToBlobNew(e)          
            const fileOfBlob = new File([file], `file${index}.jpg`, {type:'image/jpeg'})        
            formData.append('file_'+index, fileOfBlob)
          }
      })

      //기본정보
      const memId = Cookies.get('memId')
      formData.append('ord_number', route.params.num)
      formData.append('mem_id', memId)
  
  
      isOpenLoading.value = true
      await addFileData(apiUrl, formData).then((response) => {

        //토글로 오류를 표기하여 따로 제어하진 않음
        isOpenLoading.value = false
        
        //정상으로 넘어오면 처리 완료 (결과 값이 따로 없음)
        if(response.status == 200){
            router.push({
              name: 'mobile-sale-application',          
              params: {
              }
          })
        }
      })
    }
  

    const handleNext = () => {
      dataUpdate()     
    }   

    //첨부파일 처리
    const handlePhoto = async (index) => {
      const image = await Camera.getPhoto({
        quality: 10,
        allowEditing: true,
        resultType: CameraResultType.DataUrl
      })

      photo.value[index] = image.dataUrl
    }

    const removePhoto = async (index) => {
      photo.value[index] = undefined
    }

    return {
      checkList,
      isOpenLoading,
      cameraOutline, 
      trash,
      handlePhoto,
      removePhoto,
      photo,
      handleNext,
      dataUpdate,
    }
  }
}
</script>

<style>
ion-list .title {
  display: block;
  margin-bottom: 20px;
  font-size: 16px;
}
/* .SurveyFile ion-grid {
  padding: 0;
} */

</style>