import request from "@/utils/request_v2";

/*
실패 값도 체크하고 싶어서 만든 로직 2023.08.24
*/

// 목록조회
export const listData = (url, query) => request({
  url: url,
  method: "get",
  params: query
});

// 상세조회
export const getData = (url, num) => request({
  url: `${url}/` + num + "/",
  method: "get"
});

// 추가
export const addData = (url, data) => request({
  url: url,
  method: "post",
  data: data
});

// 수정
export const updateData = (url, data) => request({
  url: url,
  method: "put",
  data: data
});

// 개별수정
export const patchData = (url, data) => request({
  url: `${url}` + data.id + "/",
  method: "patch",
  data: data
});

// 삭제
export const delData = (url, id) => request({
  url: `${url}/` + id + "/",
  method: "delete"
});

// 추가
export const addFileData = (url, data) => request({
  url: url,
  method: 'post',
  headers: {
    contentType: 'multipart/form-data'
  },
  data: data
});
