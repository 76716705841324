import axios from 'axios'
import { toastController } from '@ionic/vue'
import { getToken } from '@/utils/auth'

/*
실패 값도 체크하고 싶어서 만든 로직 2023.08.24
*/

const AUTH = "vngreenatm.1.0.0:itadtechnology"
const base64Credentials = btoa(AUTH)
const myKey = `Basic ${base64Credentials}`
// create an axios instance
const service = axios.create({
	baseURL: '/', // url = base url + request url
	// withCredentials: true, // send cookies when cross-domain requests
	timeout: 1000 * 60 * 3, // request timeout
	headers: {
		'Access-Control-Allow-Origin': '*',
		'Authorization': myKey
	},
	withCredentials: true
})

// request interceptor
service.interceptors.request.use(
	config => {
		// do something before request is sent

		// token 설정 필요 여부
		const isToken = (config.headers || {}).isToken === false
		if (getToken() && !isToken) {
			config.headers["Authorization"] = "Bearer " + getToken() // 각 요청마다 사용자 정의 토큰을 가져오도록 하기 위해 상황에 맞게 수정해 주시기 바랍니다
		}

		return config
	},
	error => {
		// do something with request error
		console.error(error) // for debug
		return Promise.reject(error)
	}
)

// response interceptor
service.interceptors.response.use(
	/**
	 * If you want to get http information such as headers or status
	 * Please return  response => response
	 */

	/**
	 * Determine the request status by custom code
	 * Here is just an example
	 * You can also judge the status by HTTP Status Code
	 */
	async response => {
		// if the custom code is not 20000, it is judged as an error.
		if (response.status === 200) {
			return response
			/* if (response.data.code === 200) {
			} else {
				const toast = await toastController
        .create({
          color: 'warning',
          message: response.data.msg,
          position: 'bottom',
					duration: 2000
        })
				await toast.present()
			} */
		} else {
			const toast = await toastController
        .create({
          color: 'danger',
          message: response.data.msg,
          position: 'bottom',
					duration: 2000
        })
			await toast.present()
		}

		/* if (response.code !== 20000) {
				Message({
					message: res.message || 'Error',
					type: 'error',
					duration: 5 * 1000
				})

				// 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
				if (response.code === 50008 || res.code === 50012 || res.code === 50014) {
					// to re-login
					MessageBox.confirm(
						'You have been logged out, you can cancel to stay on this page, or log in again',
						'Confirm logout',
						{
							confirmButtonText: 'Re-Login',
							cancelButtonText: 'Cancel',
							type: 'warning'
						}
					).then(() => {
						store.dispatch('user/resetToken').then(() => {
							location.reload()
						})
					})
				}
				return Promise.reject(new Error(res.message || 'Error'))
			} else {
				return res
			} */
	},
	async (error) => {
		const toast = await toastController.create({
			color: 'danger',
			duration: 2000,
			message: error,
			showCloseButton: true,
		})
	
		await toast.present()
		Promise.reject(error)
		return error.response
	}
)

export default service
